import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import connections from '../img/screenshot-connections.bmp';
import flights from '../img/screenshot-flights.bmp';
import logmessages from '../img/screenshot-log-messages.bmp';
import packetviewer from '../img/screenshot-packet-viewer.bmp';
import paramseditor from '../img/screenshot-parameters-editor.bmp';

import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return (
    <section className="py-8 py-md-11">
      <div className="container">
        <div className="row justify-content-center">
            <h2 className="display-6 fw-bold mt-8 text-info aos-init aos-animate" data-aos="fade-up" data-aos-delay="50">
              {t('KeyFeaturesTitle')}
            </h2>
            <p className="lead mb-9">{t('KeyFeaturesDescription')}</p>
        </div>
        <div className="row align-items-center">
            <Row xs={1} md={2} className="g-4">
                <Col key={1}>
                    <Card className='feature-card'>
                        <Card.Img variant="top" src={connections} />
                        <Card.Body>
                          <Card.Title>{t('SafetyAndReliabilityTitle')}</Card.Title>
                          <Card.Text>{t('SafetyAndReliabilityDescription')}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col key={2}>
                    <Card className='feature-card'>
                        <Card.Img variant="top" src={flights} />
                        <Card.Body>
                          <Card.Title>{t('AutonomousFlightsTitle')}</Card.Title>
                          <Card.Text>{t('AutonomousFlightsDescription')}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col key={3}>
                    <Card className='feature-card'>
                        <Card.Img variant="top" src={paramseditor} />
                        <Card.Body>
                          <Card.Title>{t('AdvancedControlTitle')}</Card.Title>
                          <Card.Text>{t('AdvancedControlDescription')}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col key={4}>
                    <Card className='feature-card'>
                        <Card.Img variant="top" src={packetviewer} />
                        <Card.Body>
                        <Card.Title>{t('IntuitiveInterfaceTitle')}</Card.Title>
                        <Card.Text>{t('IntuitiveInterfaceDescription')}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
      </div>
    </section>
  );
};