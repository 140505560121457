import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import connections from '../img/screenshot-connections.bmp';
import flights from '../img/screenshot-flights.bmp';
import logmessages from '../img/screenshot-log-messages.bmp';
import packetviewer from '../img/screenshot-packet-viewer.bmp';
import paramseditor from '../img/screenshot-parameters-editor.bmp';

export default () => {
  return (
    <div className='app-swiper-background'>
      <Swiper className='app-swiper' modules={[Autoplay, Navigation, Pagination, A11y]}
        spaceBetween={50} slidesPerView={1} navigation loop
        autoplay={{ delay: 3500, disableOnInteraction: false }}
        pagination={{ clickable: true }}>
        <SwiperSlide className='app-swiper-slide'>
          <img src={connections} alt='' className='app-swiper-slide-image'/>
        </SwiperSlide>
        <SwiperSlide className='app-swiper-slide'>
          <img src={flights} alt='' className='app-swiper-slide-image'/>
        </SwiperSlide>
        <SwiperSlide className='app-swiper-slide'>
          <img src={logmessages} alt='' className='app-swiper-slide-image'/>
        </SwiperSlide>
        <SwiperSlide className='app-swiper-slide'>
          <img src={packetviewer} alt='' className='app-swiper-slide-image'/>
        </SwiperSlide>
        <SwiperSlide className='app-swiper-slide'>
          <img src={paramseditor} alt='' className='app-swiper-slide-image'/>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};