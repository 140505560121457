import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiDownload } from '@mdi/js';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

    return (
    <section className="py-md-4">
      <Container>
        <div className="card bg-asv-dark shadow-light-lg overflow-hidden text-center text-lg-start">
          <Row>
            <Col>
              <div className="card-body pt-6">
                <Row className="align-items-center">
                  <Col md>
                    <h2 className="fw-bold mb-1 text-white">
                      {t('DownloadsSectionTitle')}
                    </h2>
                    <p className="text-white mb-5 mb-md-0">
                      {t('DownloadsSectionDescription')}
                    </p>
                  </Col>
                  <Col md="auto">
                  <Button variant="primary" href="https://github.com/asv-soft/asv-drones/releases" className="btn-primary-desat" 
                        style={{ display: 'inline-flex', alignItems: 'center' }}>
                        {t('DownloadsTitle')}
                        <Icon style={{marginLeft: '5px'}} path={mdiDownload} size={1} />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    );
}