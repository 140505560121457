import React from 'react';

import Swiper from './components/application-images-swiper';
import Downloads from './components/downloads-section';
import Navigation from './components/navigation-menu';
import Platforms from './components/platforms'
import FeatureCards from './components/feature-cards'
import Footer from './components/footer';

const App = () => {
  return (
    <>
      <Navigation/>
      <Downloads/>
      <Platforms/>
      <Swiper/>
      <FeatureCards/>
      <Footer/>
    </>
  );
};

export default App;