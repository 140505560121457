import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/main.css'

import cookies from 'js-cookie'
import './components/i18n';
import timezones from './components/timezones.json'

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

var currentLanguageCode = cookies.get('i18next');

if(!currentLanguageCode){
  currentLanguageCode = timezones[timezone].c[0].toLowerCase() === 'ru' ? 'ru' : 'en';
  cookies.set('i18next', currentLanguageCode);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);