import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Logo from '../img/icon.png'
import Github from '../img/github.svg'
import YouTube from '../img/youtube.svg'
import Telegram from '../img/telegram.svg'

import Icon from '@mdi/react';
import { mdiAirplaneTakeoff } from '@mdi/js';

import { useTranslation } from 'react-i18next';

export default () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const switchLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const isActiveLanguage = (lng) => i18n.language.startsWith(lng);
  
    return (
        <header>
      <Navbar bg="transparent" variant="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand style={{ fontWeight: 'bold', display: 'inline-flex', alignItems: 'center' }}>
            <img src={Logo} style={{marginRight: '10px'}} width="35px" height="35px"
              className="navbar-brand-img" alt="Avalonia Logo"/>
            Asv.Drones
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarCollapse" />
          <Navbar.Collapse id="navbarCollapse">
            <Nav className="ms-auto">
              <Nav.Link href="https://docs.asv.me/">{t('DocumentationTitle')}</Nav.Link>
              <Nav.Link href="https://github.com/asv-soft/asv-drones/releases">{t('DownloadsTitle')}</Nav.Link>
              <NavDropdown title={t('ProjectsTitle')} id="navbarProjects">
                <NavDropdown.Item href="https://github.com/asv-soft/asv-drones" style={{display: 'inline-flex', alignItems: 'center'}}>
                <img src={Github} style={{marginRight: '10px'}} width="20px" height="20px"
              className="navbar-brand-img" alt="Avalonia Logo"/>
                  Asv.Drones.Gui
                </NavDropdown.Item>
                <NavDropdown.Item href="https://github.com/asv-soft/asv-drones-sdr" style={{display: 'inline-flex', alignItems: 'center'}}>
                <img src={Github} style={{marginRight: '10px'}} width="20px" height="20px"
              className="navbar-brand-img" alt="Avalonia Logo"/>
                  Asv.Drones.Sdr
                </NavDropdown.Item>
                <NavDropdown.Item href="https://github.com/asv-soft/asv-drones-gbs" style={{display: 'inline-flex', alignItems: 'center'}}>
                <img src={Github} style={{marginRight: '10px'}} width="20px" height="20px"
              className="navbar-brand-img" alt="Avalonia Logo"/>
                  Asv.Drones.Gbs
                </NavDropdown.Item>
                <NavDropdown.Item href="https://github.com/asv-soft/asv-mavlink" style={{display: 'inline-flex', alignItems: 'center'}}>
                <img src={Github} style={{marginRight: '10px'}} width="20px" height="20px"
              className="navbar-brand-img" alt="Avalonia Logo"/>
                  Asv.Mavlink
                </NavDropdown.Item>                
                <NavDropdown.Item href="https://github.com/asv-soft/asv-gnss" style={{display: 'inline-flex', alignItems: 'center'}}>
                <img src={Github} style={{marginRight: '10px'}} width="20px" height="20px"
              className="navbar-brand-img" alt="Avalonia Logo"/>
                  Asv.Gnss
                </NavDropdown.Item>
                <NavDropdown.Item href="https://github.com/asv-soft/asv-avalonia-toolkit" style={{display: 'inline-flex', alignItems: 'center'}}>
                <img src={Github} style={{marginRight: '10px'}} width="20px" height="20px"
              className="navbar-brand-img" alt="Avalonia Logo"/>
                  Asv.Avalonia.Toolkit
                </NavDropdown.Item>
                <NavDropdown.Item href="https://github.com/asv-soft/asv-avalonia-map" style={{display: 'inline-flex', alignItems: 'center'}}>
                <img src={Github} style={{marginRight: '10px'}} width="20px" height="20px"
              className="navbar-brand-img" alt="Avalonia Logo"/>
                  Asv.Avalonia.Map
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title={t('ResourcesTitle')} id="navbarResources">
                <NavDropdown.Item href="https://t.me/asvsoft" style={{display: 'inline-flex', alignItems: 'center'}}>
                <img src={Telegram} style={{marginRight: '10px'}} width="20px" height="20px"
              className="navbar-brand-img" alt="Avalonia Logo"/>
                  Telegram
                </NavDropdown.Item>
                <NavDropdown.Item href="https://youtube.com" style={{display: 'inline-flex', alignItems: 'center'}}>
                <img src={YouTube} style={{marginRight: '10px'}} width="20px" height="20px"
              className="navbar-brand-img" alt="Avalonia Logo"/>
                  YouTube
                </NavDropdown.Item>
                <NavDropdown.Item href="https://github.com/asv-soft" style={{display: 'inline-flex', alignItems: 'center'}}>
                <img src={Github} style={{marginRight: '10px'}} width="20px" height="20px"
              className="navbar-brand-img" alt="Avalonia Logo"/>
                  Asv.Soft
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title={t('LanguageTitle')} id="navbarLanguages">
                <NavDropdown.Item style={{display: 'inline-flex', alignItems: 'center'}}
                  onClick={() => switchLanguage('en')}
                  className={isActiveLanguage('en') ? 'active-language' : ''}>
                  {t('EnglishTitle')}
                </NavDropdown.Item>
                <NavDropdown.Item style={{display: 'inline-flex', alignItems: 'center'}}
                  onClick={() => switchLanguage('ru')}
                  className={isActiveLanguage('ru') ? 'active-language' : ''}>
                  {t('RussianTitle')}
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Button variant="primary" href="https://docs.asv.me/overview/installation-and-setup/downloading-and-installing-the-software" className="ms-auto" style={{display: 'inline-flex', alignItems: 'center'}}>
              {t('GetStartedTitle')}
              <Icon style={{marginLeft: '10px'}} path={mdiAirplaneTakeoff} size={1} />
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
    );
}  