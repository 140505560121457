import React from 'react';

import windows from '../img/platform-windows.svg';
import linux from '../img/platform-tux.svg';
import macos from '../img/platform-macos.svg';
import android from '../img/platform-android.svg';
import ios from '../img/platform-ios.svg';

import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="row justify-content-center">
        <h1 class="display-6 fw-bold mt-8 text-info aos-init aos-animate" data-aos="fade-up" data-aos-delay="50">
          {t('PlatformsSectionTitle')}
        </h1>
        <div className="flex flex-wrap platforms-container justify-content-center">

          <div className="aos-init aos-animate" data-aos="fade-up" data-aos-delay="160" data-aos-easing="ease">
            <a href="https://docs.asv.me/overview/installation-and-setup/system-requirements">
              <img src={windows} alt="Windows Logo" className="platforms-icon" aria-label="Windows 10 and newer" />
            </a>
          </div>
          <div className="aos-init aos-animate" data-aos="fade-up" data-aos-delay="160" data-aos-easing="ease">
            <a href="https://docs.asv.me/overview/installation-and-setup/system-requirements">
              <img src={linux} alt="Linux Logo" className="platforms-icon" aria-label="Desktop &amp; Embedded Linux" />
            </a>
          </div>
          <div className="aos-init aos-animate" data-aos="fade-up" data-aos-delay="160" data-aos-easing="ease">
            <a href="https://docs.asv.me/overview/installation-and-setup/system-requirements">
              <img src={macos} alt="macOS Logo" className="platforms-icon" aria-label="macOS 10.12 or newer" />
            </a>
          </div>
          <div className="aos-init aos-animate" data-aos="fade-up" data-aos-delay="160" data-aos-easing="ease">
            <a href="https://docs.asv.me/overview/installation-and-setup/system-requirements">
              <img src={android} alt="Android Logo" className="platforms-icon" aria-label="Android 12.0 (API 33)" />
            </a>
          </div>
          <div className="aos-init aos-animate" data-aos="fade-up" data-aos-delay="160" data-aos-easing="ease">
            <a href="https://docs.asv.me/overview/installation-and-setup/system-requirements">
              <img src={ios} alt="iOS Logo" className="platforms-icon" aria-label="iOS 11 and newer" />
            </a>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-10 col-lg-8 text-center">
          <div className="aos-init aos-animate" data-aos="fade-up" data-aos-delay="160" data-aos-easing="ease">
            <h6 className="text-uppercase text-warning">{t('SupportedPlatformsTitle')}</h6>
          </div>
        </div>
      </div>
    </>
  );
};