import React from 'react';

import github from '../img/github.svg';
import youtube from '../img/youtube.svg';
import telegram from '../img/telegram.svg';

import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return (
    <footer className="py-8 py-md-8 bg-asv-dark mt-auto">
      <div className="container" style={{ maxWidth: '1600px', marginTop: "10%" }}>
        <div className="row">
          <div className="col-12 col-md-4 col-lg-2" style={{ marginTop: "20px" }}>
            <h4 className="text-white"><b>Asv.Drones</b></h4>
            <p className="text-gray-700 mb-2">
              {t('FooterDescription')}
            </p>
            <ul className="list-unstyled list-inline list-social mb-6 mb-md-0">    
              <li className="list-inline-item list-social-item me-3">
                <a href="https://github.com/asv-soft" className="text-decoration-none">
                  <img src={github} className="list-social-icon" alt="GitHub"/>
                </a>
              </li>
              <li className="list-inline-item list-social-item me-3">
                <a href="https://youtube.com" className="text-decoration-none">
                  <img src={youtube} className="list-social-icon" alt="YouTube"/>
                </a>
              </li>
              <li className="list-inline-item list-social-item me-3">
                <a href="https://t.me/asvsoft" className="text-decoration-none">
                  <img src={telegram} className="list-social-icon" alt="Telegram"/>
                </a>
              </li>
            </ul>
          </div>

          <div className="col-6 col-md-4 col-lg-2" style={{ marginTop: "20px" }}>
            <h6 className="fw-bold text-uppercase text-gray-700">
              {t('ProjectsTitle')}
            </h6>
            <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
              <li className="mb-3">
                <a href="https://github.com/asv-soft/asv-drones">
                  Asv.Drones
                </a>
              </li>
              <li className="mb-3">
                <a href="https://github.com/asv-soft/asv-drones-gbs">
                  Asv.Gbs
                </a>
              </li>
              <li className="mb-3">
                <a href="https://github.com/asv-soft/asv-drones-sdr">
                  Asv.Sdr
                </a>
              </li>
              <li className="mb-3">
                <a href="https://github.com/asv-soft/asv-avalonia-toolkit">
                  Asv.Avalonia.Toolkit
                </a>
              </li>
              <li className="mb-3">
                <a href="https://github.com/asv-soft/asv-gnss">
                  Asv.Gnss
                </a>
              </li>
              <li className="mb-3">
                <a href="https://github.com/asv-soft/asv-mavlink">
                  Asv.Mavlink
                </a>
              </li>
              <li className="mb-3">
                <a href="https://github.com/asv-soft/asv-avalonia-map">
                  Asv.Avalonia.Map
                </a>
              </li>
            </ul>
          </div>


          <div className="col-6 col-md-4 col-lg-2" style={{ marginTop: "20px" }}>
            <h6 className="fw-bold text-uppercase text-gray-700">
            {t('ResourcesTitle')}
            </h6>
            <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
              <li className="mb-3">
                <a href="https://docs.asv.me/">
                  {t('DocumentationTitle')}
                </a>
              </li>
            </ul>
          </div>

        </div>

        <div className="container mt-5 text-gray-700 text-center">
          © 2024 - Asv.Soft.
        </div>
        <div className="container text-gray-700 text-center">
          {t('FooterTrademark')}
        </div>
      </div>
    </footer>
  );
};